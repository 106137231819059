import revive_payload_client_YTGlmQWWT5 from "/Users/harinvchirappanath/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/Users/harinvchirappanath/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/Users/harinvchirappanath/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/Users/harinvchirappanath/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/Users/harinvchirappanath/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/Users/harinvchirappanath/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_HXqymDRHmq from "/Users/harinvchirappanath/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/Users/harinvchirappanath/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/Users/harinvchirappanath/Documents/console/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/harinvchirappanath/Documents/console/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/Users/harinvchirappanath/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/Users/harinvchirappanath/Documents/console/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import primevue_plugin_egKpok8Auk from "/Users/harinvchirappanath/Documents/console/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/Users/harinvchirappanath/Documents/console/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import error_handler_kEP5FliEXj from "/Users/harinvchirappanath/Documents/console/plugins/error-handler.ts";
import sentry_3AyO8nEfhE from "/Users/harinvchirappanath/Documents/console/plugins/sentry.ts";
import update_csp_IDb5yIFQ3J from "/Users/harinvchirappanath/Documents/console/plugins/update-csp.mjs";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  view_transitions_client_HXqymDRHmq,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_client_UYiXMU8ZyN,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  error_handler_kEP5FliEXj,
  sentry_3AyO8nEfhE,
  update_csp_IDb5yIFQ3J
]