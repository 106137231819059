import { default as adminTTHauJSNHcMeta } from "/Users/harinvchirappanath/Documents/console/pages/admin.vue?macro=true";
import { default as data9Z7kx5g6yjMeta } from "/Users/harinvchirappanath/Documents/console/pages/data.vue?macro=true";
import { default as faq4RW7GgpTnUMeta } from "/Users/harinvchirappanath/Documents/console/pages/guides/faq.vue?macro=true";
import { default as indexvHIm2q9yxJMeta } from "/Users/harinvchirappanath/Documents/console/pages/guides/index.vue?macro=true";
import { default as indexThIZEVE0kAMeta } from "/Users/harinvchirappanath/Documents/console/pages/index.vue?macro=true";
import { default as maintenanceyVGOgFwWvAMeta } from "/Users/harinvchirappanath/Documents/console/pages/maintenance.vue?macro=true";
import { default as apr_452023thQ7evepOGMeta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/apr-2023.vue?macro=true";
import { default as apr_452025EhE1fqu2ixMeta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/apr-2025.vue?macro=true";
import { default as aug_45202327qmrbzwU0Meta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/aug-2023.vue?macro=true";
import { default as aug_452024bhuhpdimBPMeta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/aug-2024.vue?macro=true";
import { default as dec_452023I0MGUW4ncEMeta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/dec-2023.vue?macro=true";
import { default as dec_452024ZssWDho1hSMeta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/dec-2024.vue?macro=true";
import { default as feb_4520236eRlwJuOCtMeta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/feb-2023.vue?macro=true";
import { default as feb_452025aRa4HbZr8SMeta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/feb-2025.vue?macro=true";
import { default as indexDIrBCkcGQSMeta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/index.vue?macro=true";
import { default as jan_452024UoTrxKxIFLMeta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/jan-2024.vue?macro=true";
import { default as jan_452025ZdqSRfGL5sMeta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/jan-2025.vue?macro=true";
import { default as jul_452023BsecbP8ArnMeta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/jul-2023.vue?macro=true";
import { default as jul_452024ESd2VwFiePMeta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/jul-2024.vue?macro=true";
import { default as jun_4520238hEEVrXLnWMeta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/jun-2023.vue?macro=true";
import { default as jun_4520247c9hmteUYOMeta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/jun-2024.vue?macro=true";
import { default as mar_452023kKISG9OljKMeta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/mar-2023.vue?macro=true";
import { default as mar_452024jjFg9L8BNaMeta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/mar-2024.vue?macro=true";
import { default as mar_452025qtzfW50DauMeta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/mar-2025.vue?macro=true";
import { default as may_452023Jav157XzHoMeta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/may-2023.vue?macro=true";
import { default as may_452024hrI9v6EO7xMeta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/may-2024.vue?macro=true";
import { default as nov_452023QSSS3Dhpy2Meta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/nov-2023.vue?macro=true";
import { default as nov_452024ZSbAhG9t5OMeta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/nov-2024.vue?macro=true";
import { default as oct_452023vGb51JreOLMeta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/oct-2023.vue?macro=true";
import { default as oct_452024f1KMLoxLZmMeta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/oct-2024.vue?macro=true";
import { default as sep_452023ulK2cVVHJ2Meta } from "/Users/harinvchirappanath/Documents/console/pages/release-notes/sep-2023.vue?macro=true";
import { default as commercial_45analyticsumvzqMPOeGMeta } from "/Users/harinvchirappanath/Documents/console/pages/sample-dashboards/commercial-analytics.vue?macro=true";
import { default as indexT2rS4UssQAMeta } from "/Users/harinvchirappanath/Documents/console/pages/sample-dashboards/index.vue?macro=true";
import { default as mdm_45customer_45masterjQPG8S7PQjMeta } from "/Users/harinvchirappanath/Documents/console/pages/sample-dashboards/mdm-customer-master.vue?macro=true";
import { default as sales_45opsrJ8Pw3TNrcMeta } from "/Users/harinvchirappanath/Documents/console/pages/sample-dashboards/sales-ops.vue?macro=true";
import { default as indexgIpduUQEpFMeta } from "/Users/harinvchirappanath/Documents/console/pages/schema/index.vue?macro=true";
import { default as v2lIsFBKImgHMeta } from "/Users/harinvchirappanath/Documents/console/pages/schema/v2.vue?macro=true";
import { default as v3vSeVcxfN6rMeta } from "/Users/harinvchirappanath/Documents/console/pages/schema/v3.vue?macro=true";
import { default as indexMHyFQfFE9xMeta } from "/Users/harinvchirappanath/Documents/console/pages/viz/index.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/admin.vue")
  },
  {
    name: "data",
    path: "/data",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/data.vue")
  },
  {
    name: "guides-faq",
    path: "/guides/faq",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/guides/faq.vue")
  },
  {
    name: "guides",
    path: "/guides",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/guides/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceyVGOgFwWvAMeta || {},
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/maintenance.vue")
  },
  {
    name: "release-notes-apr-2023",
    path: "/release-notes/apr-2023",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/apr-2023.vue")
  },
  {
    name: "release-notes-apr-2025",
    path: "/release-notes/apr-2025",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/apr-2025.vue")
  },
  {
    name: "release-notes-aug-2023",
    path: "/release-notes/aug-2023",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/aug-2023.vue")
  },
  {
    name: "release-notes-aug-2024",
    path: "/release-notes/aug-2024",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/aug-2024.vue")
  },
  {
    name: "release-notes-dec-2023",
    path: "/release-notes/dec-2023",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/dec-2023.vue")
  },
  {
    name: "release-notes-dec-2024",
    path: "/release-notes/dec-2024",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/dec-2024.vue")
  },
  {
    name: "release-notes-feb-2023",
    path: "/release-notes/feb-2023",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/feb-2023.vue")
  },
  {
    name: "release-notes-feb-2025",
    path: "/release-notes/feb-2025",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/feb-2025.vue")
  },
  {
    name: "release-notes",
    path: "/release-notes",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/index.vue")
  },
  {
    name: "release-notes-jan-2024",
    path: "/release-notes/jan-2024",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/jan-2024.vue")
  },
  {
    name: "release-notes-jan-2025",
    path: "/release-notes/jan-2025",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/jan-2025.vue")
  },
  {
    name: "release-notes-jul-2023",
    path: "/release-notes/jul-2023",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/jul-2023.vue")
  },
  {
    name: "release-notes-jul-2024",
    path: "/release-notes/jul-2024",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/jul-2024.vue")
  },
  {
    name: "release-notes-jun-2023",
    path: "/release-notes/jun-2023",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/jun-2023.vue")
  },
  {
    name: "release-notes-jun-2024",
    path: "/release-notes/jun-2024",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/jun-2024.vue")
  },
  {
    name: "release-notes-mar-2023",
    path: "/release-notes/mar-2023",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/mar-2023.vue")
  },
  {
    name: "release-notes-mar-2024",
    path: "/release-notes/mar-2024",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/mar-2024.vue")
  },
  {
    name: "release-notes-mar-2025",
    path: "/release-notes/mar-2025",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/mar-2025.vue")
  },
  {
    name: "release-notes-may-2023",
    path: "/release-notes/may-2023",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/may-2023.vue")
  },
  {
    name: "release-notes-may-2024",
    path: "/release-notes/may-2024",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/may-2024.vue")
  },
  {
    name: "release-notes-nov-2023",
    path: "/release-notes/nov-2023",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/nov-2023.vue")
  },
  {
    name: "release-notes-nov-2024",
    path: "/release-notes/nov-2024",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/nov-2024.vue")
  },
  {
    name: "release-notes-oct-2023",
    path: "/release-notes/oct-2023",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/oct-2023.vue")
  },
  {
    name: "release-notes-oct-2024",
    path: "/release-notes/oct-2024",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/oct-2024.vue")
  },
  {
    name: "release-notes-sep-2023",
    path: "/release-notes/sep-2023",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/release-notes/sep-2023.vue")
  },
  {
    name: "sample-dashboards-commercial-analytics",
    path: "/sample-dashboards/commercial-analytics",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/sample-dashboards/commercial-analytics.vue")
  },
  {
    name: "sample-dashboards",
    path: "/sample-dashboards",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/sample-dashboards/index.vue")
  },
  {
    name: "sample-dashboards-mdm-customer-master",
    path: "/sample-dashboards/mdm-customer-master",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/sample-dashboards/mdm-customer-master.vue")
  },
  {
    name: "sample-dashboards-sales-ops",
    path: "/sample-dashboards/sales-ops",
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/sample-dashboards/sales-ops.vue")
  },
  {
    name: "schema",
    path: "/schema",
    meta: indexgIpduUQEpFMeta || {},
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/schema/index.vue")
  },
  {
    name: "schema-v2",
    path: "/schema/v2",
    meta: v2lIsFBKImgHMeta || {},
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/schema/v2.vue")
  },
  {
    name: "schema-v3",
    path: "/schema/v3",
    meta: v3vSeVcxfN6rMeta || {},
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/schema/v3.vue")
  },
  {
    name: "viz",
    path: "/viz",
    meta: indexMHyFQfFE9xMeta || {},
    component: () => import("/Users/harinvchirappanath/Documents/console/pages/viz/index.vue")
  }
]